// seo
import { Route, Routes } from "react-router";
import Seo from "./features/seo/seo";
// header
import Header from "./layouts/header/header";
import { RoutesClient } from "./features/routes/routes";
import Footer from "./layouts/footer/footer";

const App = () => {
    return (
        <>
            <Seo
                name="vira"
                type="webapp"
                title="آکبند | مسیر هوشمند خرید دیجیتال"
                keywords="آکبند،لپتاپ،فروشگاه،کامپیوتر،لوازم جانبی،درباره،تماس"
                description="آکبند، جایی که تکنولوژی به‌روز و اصالت کالا در اولویت است! ما در آکبند با ارائه‌ی جدیدترین تجهیزات کامپیوتری، لپ‌تاپ‌های اورجینال و قطعات حرفه‌ای، تجربه‌ای مطمئن و متفاوت از خرید آنلاین را برای شما فراهم می‌کنیم. تمام محصولات ما با ضمانت اصالت کالا، گارانتی معتبر و بهترین قیمت بازار عرضه می‌شوند. اگر به دنبال خریدی بی‌دردسر، ارسال سریع و پشتیبانی حرفه‌ای هستید، آکبند انتخابی هوشمندانه برای شماست. از لپ‌تاپ‌های گیمینگ و مهندسی گرفته تا قطعات حرفه‌ای و لوازم جانبی، همه را با خیال راحت از آکبند تهیه کنید."
            />
            <Header />
            <main>
                <Routes>
                    {RoutesClient.map((link) => (
                        <Route key={link.address} path={link.address} element={link.component} />
                    ))}
                </Routes>
            </main>
            <Footer />
        </>
    )
}

export default App;