// link
import { NavLink } from "react-router-dom";

const HeaderNav = (props: object | any) => {
    return (
        <>
            {props.show ? (
                <>
                    <li className="nav-item" key={props.address}>
                        <NavLink className="nav-link text-black mx-1" to={props.address}>
                            {props.label}
                        </NavLink>
                    </li>
                </>
            ) : null}
        </>
    )
}

export default HeaderNav;