// style
import { Link } from 'react-router';
import './style/style.css'
// icon
import { IconFilter, IconHeart, IconShoppingBag, IconShoppingBagPlus, IconStar, IconX } from "@tabler/icons-react";

const Products = () => {
    return (
        <>
            <section className="bg-white py-md-5 py-4">
                <div className="container">
                    <div className="row gy04">
                        <div className="col-lg-3 d-md-inline-block d-none col-md-4">
                            <div className="card product-swicher position-sticky card-body border-0 shadow rounded rounded-4">
                                <input className="form-control py-3" type="search" placeholder="جست و جو..."></input>
                                <div className="accordion mt-3" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button shadow-none bg-white " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                دسته بندی ها
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body pt-0">
                                                <ul className="list-group px-0 py-0 mt-0">
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">هارد دیسک</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">هارد دیسک</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">هارد دیسک</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">هارد دیسک</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button shadow-none bg-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                انتخاب رنگ
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body pt-0">
                                                <ul className="list-group px-0 py-0 mt-0">
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">آبی</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">سیاه</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">سفید</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button shadow-none bg-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                برند ها
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <ul className="list-group px-0 py-0 mt-0">
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">ایسوس</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">اینتل</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item px-0">
                                                        <div className="d-flex font-medium-lg justify-content-between">
                                                            <span className="text-muted">ام اس آی</span>
                                                            <div className="form-switch">
                                                                <input className="form-check-input" type="checkbox"></input>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="card card-body rounded rounded-4 border-0 shadow">
                                <div className="category-type">
                                    <ul className="nav mt-1">
                                        <li className="nav-item">
                                            مرتب سازی بر اساس:
                                        </li>
                                        <li className="nav-item text-site fw-bold px-4">
                                            جدیدترین
                                        </li>
                                        <li className="nav-item px-md-4">
                                            گران ترین
                                        </li>
                                    </ul>
                                </div>
                                <div className="row gy-4 mt-1">
                                    {Array.from({ length: 9 }, (_, index) => (
                                        <div className="col-md-4" key={index + 'product'}>
                                            <Link to={'/project/detail/3'} className='text-decoration-none'>
                                                <div data-aos="fade-down"
                                                    data-aos-duration="1500" className="card new-product-card category-card rounded rounded-4 border border-1">
                                                    <div className=" rounded rounded-4 p-3">
                                                        <span className="badge bg-white text-dark icon shadow">جدید</span>
                                                        <div className="d-flex justify-content-center">
                                                            <img src={require('./../../assets/media/global/kase.png')} alt="product" />
                                                        </div>
                                                        <div className="color-place icon">
                                                            <div className="like-place shadow">
                                                                <IconHeart size={18} />
                                                            </div>
                                                            <div className="like-place mt-2 shadow">
                                                                <IconShoppingBagPlus size={18} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body mt-1">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <span className="text-dark fw-bold font-medium-lg mb-0">
                                                                کیس  گیمینگ
                                                            </span>
                                                            <div className="stars text-warning font-medium-lg align-middle">
                                                                <IconStar size={14} />
                                                                <IconStar size={14} />
                                                                <IconStar size={14} />
                                                                <IconStar size={14} />
                                                            </div>
                                                        </div>
                                                        <p className="card-text font-medium mb-0 mt-2">
                                                            خرید کیس گیمینگ و کاستوم شده با بهترین کیفیت و گارانتی
                                                        </p>
                                                        <hr />
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <IconShoppingBag className="align-middle" />
                                                            <p className="card-title fw-bold price font-medium-lg mb-0">32,000,000  تومان</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="modal fade" id="swicher">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between">
                            <h5 className='text-site mb-0 fw-bold'>انتخاب دسته بندی</h5>
                            <IconX data-bs-dismiss="modal" />
                        </div>
                        <div className="modal-body">
                            <input className="form-control py-3" type="search" placeholder="جست و جو..."></input>
                            <div className="accordion mt-3" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button shadow-none bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            دسته بندی ها
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body pt-0">
                                            <ul className="list-group px-0 py-0 mt-0">
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">هارد دیسک</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">هارد دیسک</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">هارد دیسک</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">هارد دیسک</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button shadow-none bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            انتخاب رنگ
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body pt-0">
                                            <ul className="list-group px-0 py-0 mt-0">
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">آبی</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">سیاه</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">سفید</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button shadow-none bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            برند ها
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <ul className="list-group px-0 py-0 mt-0">
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">ایسوس</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">اینتل</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item px-0">
                                                    <div className="d-flex font-medium-lg justify-content-between">
                                                        <span className="text-muted">ام اس آی</span>
                                                        <div className="form-switch">
                                                            <input className="form-check-input" type="checkbox"></input>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white d-md-none d-inline-block'>
                <button className='swicher-btn btn shadow' data-bs-toggle="modal" data-bs-target="#swicher">
                    <IconFilter />
                </button>
            </section>
        </>
    )
}

export default Products;