// images
export const TopSlider = [
    {
        title: '',
        description: '',
        image: require('./../../assets/media/banner/banner_1.jpg')
    },
    {
        title: '',
        description: '',
        image: require('./../../assets/media/banner/banner_2.jpg')
    },
    {
        title: '',
        description: '',
        image: require('./../../assets/media/banner/banner_3.jpg')
    },
]