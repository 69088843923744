// link
import { Link } from "react-router";
// style
import './style/header.css'
// routes
import { RoutesClient } from "../../features/routes/routes";
// icon
import { IconDiscount, IconShoppingBag, IconUserCircle, IconX } from "@tabler/icons-react";
// hook
import { useState } from "react";
// mobile nav
import MobileNav from "../../components/mobileNav/mobileNav";
import HeaderNav from "../../components/headerNav/headerNav";

const Header = () => {
    const [offer, setOffer] = useState<boolean>(false)
    const ChangeStatus = () => {
        setOffer(true)
    }
    return (
        <>
            <header className="position-sticky">
                <div className={offer ? 'd-none' : 'offer'}>
                    <p className="text-center mb-0">
                        <IconDiscount size={20} className="me-2" />
                        50 درصد تخفیف لپتاپ برای مشتریان عزیز
                        <IconX size={20} className="ms-2" onClick={ChangeStatus} />
                    </p>
                </div>
                <nav className="navbar navbar-expand-md bg-light">
                    <section className="container">
                        <Link className="navbar-brand" to="#">
                            <img alt="logo" src={require('./../../assets/media/logo/final.png')} className="logo-img" />
                        </Link>
                        <button className="navbar-toggler" type="button">
                            <IconUserCircle data-bs-toggle="modal" data-bs-target="#signup" size={32} className="mx-2" />
                            <IconShoppingBag size={32} className="me-0" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-between align-items-center" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {RoutesClient.map((address) => (
                                    <HeaderNav show={address.show} key={address.address} label={address.label} address={address.address} />
                                ))}
                            </ul>
                            <div className="actions">
                                <div className="d-flex gx-4">
                                    <input type="search" className="form-control text-white font-medium py-1" placeholder="دنبال چی میگردی" />
                                    <IconUserCircle size={42} className="mx-2" />
                                    <IconShoppingBag size={42} />
                                </div>
                            </div>
                        </div>
                    </section>
                </nav>
            </header>
            <MobileNav routes={RoutesClient} />
        </>
    )
}

export default Header;