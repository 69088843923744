/* eslint-disable jsx-a11y/alt-text */
import "./style/detail.css"
// hook
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
// icons
import {
    IconStarFilled,
    IconListSearch,
    IconBubbleText,
    IconChevronLeft,
    IconHeart,
    IconTruck,
    IconTextCaption,
    IconShoppingBagPlus, IconStar, IconShoppingBag,
    IconShare,
    IconChartArcs,
    IconBell
} from '@tabler/icons-react';
// link
import { Link } from "react-router";

const Detail = () => {
    return (
        <>
            <section className="bg-white app">
                <div className="container">
                    <div className="row overflow-x-hidden">
                        <div className="col-lg-5 col-md-6 col-12 mt-5 position-relative">
                            <div className="product-icons start-0  position-absolute">
                                <ul className="list-group">
                                    <li className="list-group-item"><IconHeart /></li>
                                    <li className="list-group-item"><IconShare /></li>
                                    <li className="list-group-item"><IconChartArcs /></li>
                                    <li className="list-group-item"><IconBell /></li>
                                </ul>
                            </div>
                            <div className="offer-detail-suggest px-4 d-flex align-items-center justify-content-between">
                                <p className="offer-detail-suggest-child mb-2">شگفتانه</p>
                                <p className="offer-detail-suggest-child2 mb-2">04:12:55</p>
                            </div>
                            <div className="product-img mt-2" data-aos="fade-right">
                                <div className="img-detail-main d-flex align-items-center justify-content-center">
                                    <img src={require('../../assets/media/global/kase.png')} />
                                </div>
                                <div className=" row overflow-x-hidden justify-content-center">
                                    {Array.from({ length: 4 }, (_, index) => (
                                        <div className="col-3 mt-4">
                                            <img className="img-detail-far-img img-fluid"
                                                src={require("../../assets/media/global/Rectangle 1021.png")} alt="product" />
                                        </div>
                                    ))}
                                </div>
                                <div className="card card-body py-3 mt-4">
                                    <div className="d-flex">
                                        <div className="border-end pe-3">
                                            <input className="form-check-input"></input>
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                            <p className="font-medium-lg fw-bold mb-0">
                                                بیمه تجهیزات دیجیتال - بیمه سامان
                                            </p>
                                            <p className="font-medium-lg text-site fw-bold mb-0">
                                                30 هزار تومان
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12 mt-5">
                            <p className="detail-product-name mb-0 mt-1">
                                کیس گیمینگ ایسوس
                            </p>
                            <div className="d-md-flex d-none align-items-center justify-content-between mt-2">
                                <p className="text-muted mb-0">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                                    صنعت
                                </p>
                                <div className="ltr">
                                    <img src={require('../../assets/media/icon/asus.png')} />
                                    <div className="mt-2">
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <IconStarFilled color="yellow" size="20px" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex mt-md-0 mt-3 align-items-center">
                                <div className="d-flex align-content-center me-2">
                                    <IconStarFilled color="#F9AE2E" size={12} />
                                    <p className="font-medium ms-1 mb-0">4.6</p>
                                </div>
                                <i className="mx-1 font-medium text-2 rounded rounded-3">کیس گیمینگ</i>
                                <i className="mx-1 font-medium text-2 rounded rounded-3">کیس گیمینگ</i>
                            </div>
                            <div className="row overflow-x-hidden gy-5 mt-md-4 mt-1">
                                <div className="col-md-6 mt-4">
                                    <div className="detail-color fw-bold">
                                        <p>رنگ : {"خاکستری"}</p>
                                        <input type="radio" />
                                        <input type="radio" className="mx-3" />
                                        <input type="radio" />
                                    </div>
                                    <div className="mt-4 fw-bold">
                                        <p color={"#0C0C0C"}>توضیح</p>
                                        <p className={"info-t font-medium-lg line-30 card-text"}>
                                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                                            چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و
                                        </p>
                                        <div className="topic-detail-text-parent w-100 p-3">
                                            <p className="font-medium mb-0">ویژگی</p>
                                            <p className="font-medium-lg mt-1 fw-bold mb-0">توضیح ویژگی</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card card-body border-1">
                                        <div className="d-flex justify-content-center">
                                            <img className="acband-price-icon" src={require('../../assets/media/logo/final.png')} />
                                        </div>
                                        <div className="d-flex justify-content-between align-content-end">
                                            <p className="price-item-name mt-2 ms-2">لورم ایپسوم  </p>
                                            <p className="price-item-name2 mt-2 me-2">لورم ایپسوم  </p>
                                        </div>
                                        <div className="d-flex justify-content-between align-content-end mt-2 pt-2 border-top">
                                            <IconShoppingBag color={"#6C7275"} className="ms-2" />
                                            <p className="me-2">8490000 تومان</p>
                                        </div>
                                        <div className="d-grid">
                                            <button className="btn btn-primary">لورم ایپسوم  </button>
                                        </div>
                                        <p className="price-description ms-2">لورم ایپسوم متن ساختگی با تولید</p>
                                        <div className="d-flex pt-3 border-top">
                                            <IconTruck size={"16px"} color={"red"} />
                                            <p className={"delivery-price ms-2 mb-0"}>لورم ایپسوم متن ساختگی با تولید</p>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <IconTextCaption size={"16px"} color={"blue"} />
                                            <p className={"delivery-price ms-2 mb-0"}>لورم ایپسوم متن ساختگی با تولید</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <img src={require('./../../assets/media/banner/Screenshot 2025-02-23 132618.png')} alt="banner"
                        className="img-fluid product-banner-sm banner-small w-100" />
                </div>
                <div className="container mt-3">
                    <div className="row overflow-x-hidden">
                        <div className="col-12 col-md-6" data-aos="fade-up">
                            <div className="detail-text-info" >
                                <div className="d-flex w-100 justify-content-center align-content-center">
                                    <div className="line-detail"></div>
                                    <p className="detail-text-info-p text-center mt-3">توضیحات محصول</p>
                                    <div className="line-detail"></div>

                                </div>
                                <p className="detail-text-info-child line-30 card-text">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                                    چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و
                                    سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف
                                    بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت
                                    فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه
                                    ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان
                                    امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان
                                    مورد نیاز شامل حروفچینی دستاوردهای اصلی و  سوالات پیوسته اهل </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row overflow-x-hidden g-3 justify-content-center mt-2" data-aos="fade-up">
                                {Array.from({ length: 12 }, (_, index) => (
                                    <div className="col-6 col-md-3">
                                        <div className="topic-detail-text-parent w-100 p-3">
                                            <p className="topic-detail-text mb-0">ویژگی</p>
                                            <p className="topic-detail-text-child fw-bold mb-0">توضیح ویژگی</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="detail-list-info mt-5 w-100 container">
                    <div className="row overflow-x-hidden">
                        <div className="col-md-3 col-6">
                            <div className="list-detail-parA-parent d-flex justify-content-center">
                                <p className="list-detail-parA">لورم ایپسوم متن </p>
                                <IconListSearch className="list-detail-iA" />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="d-flex justify-content-center">
                                <p className="list-detail-par">لورم ایپسوم متن </p>
                                <IconBubbleText className="list-detail-i" />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="d-flex justify-content-center">
                                <p className="list-detail-par">لورم ایپسوم متن </p>
                                <IconBubbleText className="list-detail-i" />
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="d-flex justify-content-center">
                                <p className="list-detail-par">لورم ایپسوم متن </p>
                                <IconBubbleText className="list-detail-i" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-3 pt-3">
                    <div className="detail-attribute">
                        <p className="detail-attribute-p">امکانات محصول</p>
                    </div>
                    <div>
                        <p className="detail-attribute-des line-30 card-text">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                            با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                            کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و
                            متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
                            خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و دشواری
                            موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی
                            دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل </p>
                    </div>
                    <div className="joz-att d-flex w-100 justify-content-end">
                        <Link to={'#'} style={{ color: "#17ACB2", }} className="joz-att">جزییات</Link>
                        <IconChevronLeft color={"#17ACB2"} />
                    </div>
                </div>
                <div className="container mt-5 ">
                    <div className="detail-attribute">
                        <p className="detail-attribute-p">لورم ایپسوم متن</p>
                    </div>
                    <div className="mt-4">
                        <div className="border-bottom row overflow-x-hidden container">
                            <p className="detail-att-table-p col-md-4 col-6">لورم ایپسوم متن ساختگی </p>
                            <p className="detail-att-table-p fw-bold col-md-8 col-6">لورم ایپسوم متن ساختگی </p>
                        </div>
                        <div className="border-bottom row overflow-x-hidden container mt-3">
                            <p className="detail-att-table-p col-md-4 col-6">لورم ایپسوم متن ساختگی </p>
                            <p className="detail-att-table-p fw-bold col-md-8 col-6">لورم ایپسوم متن ساختگی </p>
                        </div>
                    </div>
                    <div className="joz-att d-flex w-100 justify-content-end mt-4">
                        <Link to={'#'} style={{ color: "#17ACB2", }} className="joz-att">جزییات</Link>
                        <IconChevronLeft color={"#17ACB2"} />
                    </div>
                </div>
                <div className="bg-white py-5 pb-3">
                    <div className="container">
                        <div className="row overflow-x-hidden gy-4">
                            <div className="col-md-6 mt-3">
                                <img src={require('./../../assets/media/banner/end-banner (1).png')} alt="banner"
                                    className="img-fluid" />
                            </div>
                            <div className="col-md-6 mt-4">
                                <img src={require('./../../assets/media/banner/end-banner (2).png')} alt="banner"
                                    className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex ablity-icons w-100 justify-content-center align-items-center mt-3">
                    <div className="container flex-wrap d-flex justify-content-center align-items-center shadow-sm">
                        <div className="col-6 col-sm-3 text-center">
                            <img src={require('./../../assets/media/icon/cash-on-delivery 1.png')} />
                            <p className="font-medium-lg">لورم ایپسوم متن ساختگی </p>
                        </div>
                        <div className="col-6 col-sm-3 text-center">
                            <img src={require('./../../assets/media/icon/support 1.png')} />
                            <p className="font-medium-lg">لورم ایپسوم متن ساختگی </p>
                        </div>
                        <div className="col-6 col-sm-3 text-center">
                            <img src={require('./../../assets/media/icon/days-return 1.png')} />
                            <p className="font-medium-lg">لورم ایپسوم متن ساختگی </p>
                        </div>
                        <div className="col-6 col-sm-3 text-center">
                            <img src={require('./../../assets/media/icon/original-products 1.png')} />
                            <p className="font-medium-lg">لورم ایپسوم متن ساختگی </p>
                        </div>
                    </div>
                </div>
                <div className="bg-white py-5 pb-3">
                    <div className="container">
                        <div className="d-flex justify-content-between mb-3 align-items-center">
                            <h6 className="fw-bold h5 mb-0">محصولات مشابه</h6>
                        </div>
                        <div className="new-products">
                            <Swiper
                                slidesPerView={4}
                                centeredSlides={false}
                                slidesPerGroupSkip={1}
                                spaceBetween={30}
                                grabCursor={true}
                                keyboard={{
                                    enabled: true,
                                }}
                                breakpoints={{
                                    176: {
                                        slidesPerView: 1.5,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1000: {
                                        slidesPerView: 4,
                                    },
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Keyboard, Pagination]}
                                className="mySwiper"
                            >
                                {Array.from({ length: 8 }, (_, index) => (
                                    <SwiperSlide key={index + 'product'}>
                                        <div data-aos="fade-right"
                                            data-aos-duration="1500"
                                            className="card new-product-card category-card border-0 rounded rounded-4 shadow">
                                            <div className=" rounded rounded-4 p-3">
                                                <span className="badge bg-white text-dark icon shadow">جدید</span>
                                                <div className="d-flex justify-content-center">
                                                    <img src={require('./../../assets/media/global/kase.png')}
                                                        alt="product" />
                                                </div>
                                                <div className="color-place icon">
                                                    <div className="like-place shadow">
                                                        <IconHeart size={18} />
                                                    </div>
                                                    <div className="like-place mt-2 shadow">
                                                        <IconShoppingBagPlus size={18} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body mt-1">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span className="text-dark fw-bold font-medium-lg mb-0">
                                                        کیس  گیمینگ
                                                    </span>
                                                    <div className="stars text-warning font-medium-lg align-middle">
                                                        <IconStar size={14} />
                                                        <IconStar size={14} />
                                                        <IconStar size={14} />
                                                        <IconStar size={14} />
                                                    </div>
                                                </div>
                                                <p className="card-text font-medium mb-0 mt-2">
                                                    خرید کیس گیمینگ و کاستوم شده با بهترین کیفیت و گارانتی
                                                </p>
                                                <hr />
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <IconShoppingBag className="align-middle" />
                                                    <p className="card-title fw-bold price font-medium-lg mb-0">32,000,000
                                                        تومان</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <article className="end-banner">
                <img src={require('./../../assets/media/banner/WATER-COOLING-SLIDER2 1.webp')} className="img-fluid banner-small w-100" alt="banner" />
            </article>
        </>
    )
}
export default Detail;