import { Slider } from "../../components/slider/slider";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
// style
import './style/home.css'
// icon
import { IconArrowBadgeLeft, IconHeart, IconShoppingBag, IconShoppingBagPlus, IconStar } from "@tabler/icons-react";

const Home = () => {
    return (
        <>
            <section className="banner-parent bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <Slider />
                        </div>
                        <div className="col-md-4 d-md-inline-block d-none">
                            <img src={require('./../../assets/media/banner/banner_2.jpg')} alt="banner" className="img-fluid banner-top-box rounded rounded-4" />
                            <img src={require('./../../assets/media/banner/banner_3.jpg')} alt="banner" className="img-fluid banner-top-box rounded rounded-4 mt-4" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="category bg-white py-3 mt-md-0 mt-2">
                <div className="container">
                    <Swiper
                        slidesPerView={4}
                        centeredSlides={false}
                        slidesPerGroupSkip={1}
                        spaceBetween={30}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            176: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                            1000: {
                                slidesPerView: 6,
                            },
                        }}
                        navigation={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Keyboard]}
                        className="mySwiper"
                    >
                        {Array.from({ length: 15 }, (_, index) => (
                            <SwiperSlide key={index + 'category'}>
                                <div className="card card-body border-0 category-card" >
                                    <div className="d-flex justify-content-center">
                                        <div className="image-box shadow">
                                            <img alt="category" src={require('./../../assets/media/global/CASE-CAT2 1.png')} />
                                        </div>
                                    </div>
                                    <p className="text-center mb-0 fw-bold font-medium-lg mt-3">دسته بندی</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
            <section className="new-products bg-white py-md-5 py-2 pb-3">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">جدید ترین ها</h6>
                    <Swiper
                        slidesPerView={4}
                        centeredSlides={false}
                        slidesPerGroupSkip={1}
                        spaceBetween={30}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            176: {
                                slidesPerView: 1.3,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1000: {
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Keyboard, Pagination]}
                        className="mySwiper"
                    >
                        {Array.from({ length: 15 }, (_, index) => (
                            <SwiperSlide key={index + 'category'}>
                                <div data-aos="fade-right"
                                    className="card new-product-card category-card border-0">
                                    <div className="card-image rounded rounded-4 p-3">
                                        <span className="badge bg-white text-dark icon">جدید</span>
                                        <div className="d-flex justify-content-center">
                                            <img src={require('./../../assets/media/global/kase.png')} alt="product" />
                                        </div>
                                        <div className="icon like-place shadow">
                                            <IconHeart size={18} />
                                        </div>
                                        <button className="btn btn-primary w-100 py-1 mt-3">
                                            افزودن
                                        </button>
                                    </div>
                                    <div className="card-body mt-1">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="text-dark fw-bold font-medium-lg mb-0">
                                                کیس  گیمینگ
                                            </span>
                                            <div className="stars text-warning font-medium-lg align-middle">
                                                <IconStar size={14} />
                                                <IconStar size={14} />
                                                <IconStar size={14} />
                                                <IconStar size={14} />
                                            </div>
                                        </div>
                                        <p className="card-text font-medium mb-0 mt-2">
                                            خرید کیس گیمینگ و کاستوم شده با بهترین کیفیت و گارانتی
                                        </p>
                                        <hr />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <IconShoppingBag className="align-middle" />
                                            <span className="card-title price font-medium-lg">32,000,000  تومان</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
            <section className="banners bg-white py-3 pb-3">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">تجربه بهترین خرید با ما</h6>
                    <div className="row gy-3 overflow-x-hidden">
                        <div className="col-12">
                            <div className="banner-bg px-4">
                                <div className="row h-100 align-items-center">
                                    <div className="col-md-6 col-8">
                                        <h5 className="text-white fw-bold">
                                            آکبند | خرید بهترین کیبورد و لوازم جانبی
                                        </h5>
                                        <p className="card-text text-white mt-3 mb-0 d-md-block d-none">
                                            با آکبند بهترین کیبورد هارو با مقطوع ترین قیمت پیدا میکنی
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="zoom-in"
                        >
                            <div className="banner-bg banner-bg-se px-3 pb-3">
                                <div className="row h-100 align-items-end">
                                    <div className="col-12">
                                        <h5 className="text-white fw-bold">
                                            کیس | به صرفه ترین کیس ها
                                        </h5>
                                        <p className="text-start mb-0 fw-bold font-medium text-white">
                                            مشاهده همه
                                            <IconArrowBadgeLeft size={16} className="align-middle" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="zoom-in"
                        >
                            <div className="banner-bg banner-bg-th px-3 pb-3">
                                <div className="row h-100 align-items-end">
                                    <div className="col-12">
                                        <h5 className="text-white fw-bold">
                                            ماوس | برترین برند های ماوس
                                        </h5>
                                        <p className="text-start mb-0 fw-bold font-medium text-white">
                                            مشاهده همه
                                            <IconArrowBadgeLeft size={16} className="align-middle" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6 " data-aos="zoom-in"
                        >
                            <div className="banner-bg banner-bg-fo px-3 pb-3">
                                <div className="row h-100 align-items-end">
                                    <div className="col-12">
                                        <h5 className="text-white fw-bold">
                                            میکروفون | با کیفیت و مقطوع
                                        </h5>
                                        <p className="text-start mb-0 fw-bold font-medium text-white">
                                            مشاهده همه
                                            <IconArrowBadgeLeft size={16} className="align-middle" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6" data-aos="zoom-in"
                        >
                            <div className="banner-bg banner-bg-fi px-3 pb-3">
                                <div className="banner-bg banner-bg-fo px-3 pb-3">
                                    <div className="row h-100 align-items-end">
                                        <div className="col-12">
                                            <h5 className="text-white fw-bold">
                                                مقطوع ترین لوازم
                                            </h5>
                                            <p className="text-start mb-0 fw-bold font-medium text-white">
                                                مشاهده همه
                                                <IconArrowBadgeLeft size={16} className="align-middle" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="banner-bg banner-bg-last px-4">
                                <div className="row h-100 align-items-center">
                                    <div className="col-md-6 col-8">
                                        <h5 className="text-white fw-bold">
                                            محصولات | با ما بهترین ها را تجربه کنید
                                        </h5>
                                        <p className="card-text text-white mt-3 mb-0 d-md-block d-none">
                                            با آکبند بهترین کیبورد هارو با مقطوع ترین قیمت پیدا میکنی
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="new-products more-sell bg-white py-5 pb-3">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">پرفروش ترین ها</h6>
                    <Swiper
                        slidesPerView={4}
                        centeredSlides={false}
                        slidesPerGroupSkip={1}
                        spaceBetween={30}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            176: {
                                slidesPerView: 1.3,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1000: {
                                slidesPerView: 4,
                            },
                        }}
                        modules={[Keyboard]}
                        className="mySwiper"
                    >
                        {Array.from({ length: 15 }, (_, index) => (
                            <SwiperSlide key={index + 'category'}>
                                <div data-aos="fade-left"
                                    className="card new-product-card category-card border-0">
                                    <div className="card-image rounded rounded-4 p-3">
                                        <div className="d-flex justify-content-center">
                                            <img className="img-fluid" src={require('./../../assets/media/global/keyboard.png')} alt="product" />
                                        </div>
                                        <div className="color-place icon">
                                            <div className="border like-place border-4 border-primary shadow"></div>
                                            <div className="border like-place mt-2 border-4 border-warning shadow"></div>
                                        </div>
                                    </div>
                                    <div className="card-body mt-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="text-dark fw-bold font-medium-lg mb-0">
                                                کیبورد گیمینگ
                                            </span>
                                            <div className="stars text-warning font-medium-lg align-middle">
                                                <IconStar className="text-primary" size={14} />
                                                <IconStar className="text-primary" size={14} />
                                                <IconStar className="text-primary" size={14} />
                                                <IconStar className="text-primary" size={14} />
                                                <IconStar className="text-primary" size={14} />
                                            </div>
                                        </div>
                                        <hr />
                                        <p className="card-text font-medium mb-0 mt-2">
                                            خرید کیس گیمینگ و کاستوم شده با بهترین کیفیت و گارانتی
                                        </p>
                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            <p className="card-title price mb-0 fw-bold font-medium-lg mt-2">32,000,000  تومان</p>
                                            <button className="btn btn-primary py-1 font-medium-lg">
                                                <IconShoppingBag size={16} className="align-middle me-2" />
                                                خرید
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
            <section className="bg-white py-5 pb-3">
                <div className="container">
                    <img src={require('./../../assets/media/banner/Screenshot 2025-02-23 132618.png')} alt="banner" className="img-fluid banner-small rounded rounded-4 w-100" />
                </div>
            </section>
            <section className="bg-white py-5 pb-3">
                <div className="container">
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <h6 className="fw-bold h5 mb-0">محصولات</h6>
                        <button className="btn btn-primary">مشاهده همه</button>
                    </div>
                    <div className="new-products">
                        <Swiper
                            slidesPerView={4}
                            centeredSlides={false}
                            slidesPerGroupSkip={1}
                            spaceBetween={30}
                            grabCursor={true}
                            keyboard={{
                                enabled: true,
                            }}
                            breakpoints={{
                                176: {
                                    slidesPerView: 1.5,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                1000: {
                                    slidesPerView: 4,
                                },
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Keyboard, Pagination]}
                            className="mySwiper"
                        >
                            {Array.from({ length: 8 }, (_, index) => (
                                <SwiperSlide key={index + 'product'}>
                                    <div data-aos="fade-right"
                                        className="card new-product-card category-card border-0 rounded rounded-4 shadow">
                                        <div className=" rounded rounded-4 p-3">
                                            <span className="badge bg-white text-dark icon shadow">جدید</span>
                                            <div className="d-flex justify-content-center">
                                                <img src={require('./../../assets/media/global/kase.png')} alt="product" />
                                            </div>
                                            <div className="color-place icon">
                                                <div className="like-place shadow">
                                                    <IconHeart size={18} />
                                                </div>
                                                <div className="like-place mt-2 shadow">
                                                    <IconShoppingBagPlus size={18} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body mt-1">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="text-dark fw-bold font-medium-lg mb-0">
                                                    کیس  گیمینگ
                                                </span>
                                                <div className="stars text-warning font-medium-lg align-middle">
                                                    <IconStar size={14} />
                                                    <IconStar size={14} />
                                                    <IconStar size={14} />
                                                    <IconStar size={14} />
                                                </div>
                                            </div>
                                            <p className="card-text font-medium mb-0 mt-2">
                                                خرید کیس گیمینگ و کاستوم شده با بهترین کیفیت و گارانتی
                                            </p>
                                            <hr />
                                            <div className="d-flex justify-content-between align-items-center">
                                                <IconShoppingBag className="align-middle" />
                                                <p className="card-title fw-bold price font-medium-lg mb-0">32,000,000  تومان</p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            <section className="bg-white py-5 pb-3">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-md-6 mt-3">
                            <img src={require('./../../assets/media/banner/end-banner (1).png')} alt="banner" className="img-fluid" />
                        </div>
                        <div className="col-md-6 mt-4">
                            <img src={require('./../../assets/media/banner/end-banner (2).png')} alt="banner" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white brands py-5">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">برند های پرفروش</h6>
                    <Swiper
                        slidesPerView={4}
                        centeredSlides={false}
                        slidesPerGroupSkip={1}
                        loop={true}
                        autoplay={{
                            delay: 1500
                        }}
                        spaceBetween={30}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            176: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                            1000: {
                                slidesPerView: 6,
                            },
                        }}
                        modules={[Keyboard, Autoplay]}
                        className="mySwiper"
                    >
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/msi.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/asus.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/cosair.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/deepcool.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/intel.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/cosair.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/deepcool.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/msi.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/asus.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/intel.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <article className="end-banner">
                <img src={require('./../../assets/media/banner/WATER-COOLING-SLIDER2 1.webp')} className="img-fluid banner-small w-100" alt="banner" />
            </article>
        </>
    )
}

export default Home;