// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// styles
import 'swiper/css';
import './style/style.css'
import "swiper/css/pagination";
// options
import { TopSlider } from './../../features/options/sliderOptions';
// child
import { SliderChild } from './child/child';


export function Slider() {
    return (
        <>
            <Swiper
                spaceBetween={10}
                loop={true}
                slidesPerView={1}
                pagination={{ clickable: true }}
                speed={1500}
                autoplay={{
                    delay: 2000,
                }}
                modules={[Pagination, Autoplay]}
            >
                {TopSlider?.map((slider, index: number) => (
                    <SwiperSlide key={index + 'topslider'}>
                        <SliderChild title={slider.title} description={slider.description} image={slider.image} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}