// icon
import { IconHome, IconPencil, IconPhoneCalling, IconShoppingBag, IconFileDescription } from "@tabler/icons-react";
// components
import Home from "../../pages/home/home";
import Detail from "../../pages/detail/Detail";
import ContactUs from "../../pages/contactUs/contactUs";
import AboutUs from "../../pages/aboutUs/aboutUs";
import Products from "../../pages/products/products";

export const RoutesClient = [
    {
        show: true,
        label: 'خانه',
        address: '/',
        component: <Home />,
        icon: <IconHome />,
    },
    {
        show: true,
        label: 'محصولات',
        address: '/products',
        component: <Products/>,
        icon: <IconShoppingBag />,
    },
    {
        show: true,
        label: 'درباره ما',
        address: '/aboutUs',
        component: <AboutUs />,
        icon: <IconPencil />,
    },
    {
        show: true,
        label: 'ارتباط باما',
        address: '/contactUs',
        component: <ContactUs />,
        icon: <IconPhoneCalling />,
    },
    {
        show: false,
        label: 'توضیحات محصول',
        address: '/project/detail/:id',
        component: <Detail />,
        icon: <IconFileDescription />
    }
]