// react
import React from 'react';
import ReactDOM from 'react-dom/client';
// style
import './index.css';
// app
import App from './App';
// react router
import { BrowserRouter } from "react-router-dom";
// bootstrap 
import 'bootstrap/dist/css/bootstrap.rtl.css';
import 'bootstrap/dist/css/bootstrap-grid.rtl.css'
import 'bootstrap/dist/js/bootstrap.bundle'
// animate
// @ts-ignore
import AOS from "aos";
import 'aos/dist/aos.css';
AOS.init({
  duration: 1000,
  once: true,
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


